import moment from 'moment-timezone';

export function getDate(date) {
	return moment(date).format('YYYY-MM-DD');
}

export function setStartOfTheDay(date) {
	return moment(date).hours(0).minutes(0).seconds(0).milliseconds(0);
}

export function setEndOfTheDay(date) {
	return moment(date).hours(23).minutes(59).seconds(59).milliseconds(999);
}

export function convertToUtc(date) {
	return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

export function dateToTimezone(date, toTz, fromTz, returnDate = true) {
	if (!date) {
		return date;
	}

	let mObject;
	if (fromTz) {
		mObject = moment(date).tz(fromTz).tz(toTz, true);
	} else {
		mObject = moment(date).tz(toTz, true);
	}
	return returnDate ? mObject.toDate() : mObject;
}

export function getBrowserTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function toLocalDate(date, fromTz, returnDate = true) {
	if (!date) {
		return date;
	}

	if (fromTz && fromTz !== getBrowserTimezone()) {
		return dateToTimezone(date, getBrowserTimezone(), fromTz, returnDate);
	}

	return returnDate ? date : moment(date);
}

export function toTimezoneDate(date, toTz, returnDate = true) {
	if (!date) {
		return date;
	}

	if (toTz && toTz !== getBrowserTimezone()) {
		return dateToTimezone(date, toTz, undefined, returnDate);
	}

	return returnDate ? date : moment(date);
}

/**
 * Tweak date formats for Europe (without changing from default locale "en")
 *
 * @see https://github.com/moment/moment/blob/develop/src/locale/sv.js
 */
export function updateDefaultLocale() {
	moment.updateLocale('en', {
		longDateFormat: {
			LT: 'HH:mm',
			LTS: 'HH:mm:ss',
			L: 'YYYY-MM-DD',
			LL: 'D MMMM YYYY',
			LLL: 'D MMMM YYYY HH:mm',
			LLLL: 'dddd D MMMM YYYY HH:mm'
		},
		week: {
			dow: 1, // Monday is the first day of the week.
			doy: 4 // The week that contains Jan 4th is the first week of the year.
		}
	});
}

/**
 * Check if two dates are equal
 *
 * @param {string} a
 * @param {string} b
 * @returns {boolean}
 */
export function compareDate(a, b) {
	return new Date(a).getTime() === new Date(b).getTime();
}
