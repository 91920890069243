import React, {Suspense} from 'react';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';
const App = React.lazy(() => import('./App.jsx'));

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;

	.spinner-text {
		margin-top: -74px;
		font-size: 12px;
		font-weight: 600;
		color: #bcb6ff;
	}
`;

const AppLoader = () => (
	<Suspense
		fallback={
			<Wrapper>
				<Spinner size="128px" circleStyle={{stroke: '#bcb6ff', strokeWidth: '3px'}} delay={200}>
					LOADING...
				</Spinner>
			</Wrapper>
		}
	>
		<App />
	</Suspense>
);

export default AppLoader;
