import dotProp from 'dot-prop';

/**
 * Interpolate string with the given data object
 *
 * @param {string} str
 * @param {object} data
 * @returns {string}
 */
export function interpolate(str, data) {
	const re = /\{\{([^\}]+)?\}\}/g;
	return String(str).replace(re, (_, key) => dotProp.get(data, key, ''));
}

/**
 * Make the first letter of a string uppercase
 *
 * @param  {string} string
 * @returns {string}
 */
export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Hash string using djb2 (dan bernstein) algorithm
 *
 * @param  {string} str
 * @returns {string}
 */
export function hashString(str) {
	let hash = 5381;

	for (let i = 0; i < str.length; i++) {
		hash = ((hash << 5) + hash) + str.charCodeAt(i);
	}

	return String(Math.abs(hash));
}

/**
 * Create a random identifier
 *
 * @param  {number} [length]
 * @returns {string}
 */
export function uniqueId(length = 8) {
	const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
	let text = '';

	for (let i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
}

/**
 * Return user initals
 *
 * @param {string} name
 * @returns {string}
 */
export function getInitials(name) {
	if (name) {
		return name
			.replace(/\-/g, ' ')
			.split(' ')
			.map((s) => s.charAt(0).toUpperCase())
			.slice(0, 3)
			.join('');
	}

	return '??';
}

/**
 * Return keyword matches in the given text
 *
 * @param {string} text
 * @param {string} keyword
 * @returns {Array}
 */
export function keywordMatch(text, keyword) {
	const results = [];

	if (!keyword) {
		return results;
	}

	const trimmedQuery = keyword.trim().toLowerCase();
	const textLower = text.toLowerCase();
	const queryLength = trimmedQuery.length;
	let indexOf = textLower.indexOf(trimmedQuery);

	while (indexOf > -1) {
		results.push([indexOf, indexOf + queryLength]);
		indexOf = textLower.indexOf(keyword, indexOf + queryLength);
	}

	return results;
}

/**
 * Truncate text and optionally append string to end if truncated
 *
 * @param {number} maxCharLength
 * @param {string} str
 * @param {string} [append]
 * @returns {string}
 */
export function truncate(maxCharLength, str, append) {
	if (!maxCharLength || str.length < maxCharLength) {
		return str;
	}

	return str.substr(0, maxCharLength) + (append ? append : '');
}

/**
 * Pluralize a word
 *
 * @param {string} word The word to pluralize
 * @param {number} count How many of the word exist
 * @param {boolean} [inclusive] Whether to prefix with the number (e.g. 3 ducks)
 * @returns {string}
 */
export function pluralize(word, count, inclusive = false) {
	let str = '';

	if (inclusive) {
		str += `${count} `;
	}

	str += word;

	if (count > 1) {
		str += 's';
	}

	return str;
}

export function parseIfString(value, errorValue = {}) {
	try {
		return typeof value === 'string' ? JSON.parse(value) : value;
	} catch (err) {
		return errorValue;
	}
}

export function stringifyIfObject(value) {
	return typeof value === 'object' ? JSON.stringify(value, null, '\t') : value;
}
