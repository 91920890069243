import {createReducer, createAction} from '@reduxjs/toolkit';

const SET_CONNECTION_STATE = createAction('dredition/firebase/SET_CONNECTION_STATE');

const initialState = {
	online: false
};

export default createReducer(initialState, (builder) => {
	builder
		.addCase(SET_CONNECTION_STATE, (state, action) => {
			state.online = action.online;
		});
});

export const setConnectionState = (online) => ({
	type: SET_CONNECTION_STATE.type,
	online
});
