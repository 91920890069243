// import cmp from 'semver-compare';

/**
 * Run migrations for a specific version
 *
 * Example: if (cmp(config.version, '2.0.0') <= 0) { do something... }
 *
 * @param {object} config
 */
// eslint-disable-next-line no-unused-vars
export default async function (config) {
}
