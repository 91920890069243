import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import {setConnectionState} from '../ducks/firebase';
import {getFirebaseAuth} from '../services/auth';
import {getFirebaseConfig, setFirebaseConfig} from '../utils/auth';
import errorHandler from '../utils/error-handler';
import storage from '../utils/storage';

const appConfig = window.appConfig;
let initPromise;

function signIn(token) {
	return firebase.auth().signInWithCustomToken(token);
}

function onConnect(user) {
	const userRef = firebase.database().ref(`accounts/${appConfig.clientId}/users/${user.id}`);

	userRef
		.onDisconnect()
		.remove((err) => {
			if (!err) {
				userRef.set(Object.assign({
					connectedAt: new Date().toISOString()
				}, user));
			}
		});
}

async function init() {
	const firebaseConfig = getFirebaseConfig();

	if (!firebaseConfig) {
		return;
	}

	firebase.initializeApp({
		apiKey: firebaseConfig.apiKey,
		databaseURL: firebaseConfig.databaseURL,
		authDomain: 'none' // Required to prevent warning in the console
	});

	try {
		await signIn(firebaseConfig.customToken);
	} catch (err) {
		if (err.code === 'auth/invalid-custom-token') {
			const auth = await getFirebaseAuth();
			setFirebaseConfig(auth);
			return signIn(auth.customToken);
		}

		if (err.code === 'auth/network-request-failed') {
			err.isHandled = true;
		}

		throw err;
	}
}

export function initFirebase() {
	if (!initPromise) {
		initPromise = init();
	}

	return initPromise;
}

export default async (store) => {
	await storage.init();
	const firebaseConfig = getFirebaseConfig();

	if (!firebaseConfig) {
		return;
	}

	try {
		await initFirebase();
	} catch (err) {
		errorHandler(err);
	}

	const unsubscribe = store.subscribe(() => {
		const state = store.getState();
		if (state.firebase.online && state.app.user) {
			unsubscribe();
			onConnect(state.app.user);
		}
	});

	firebase
		.database()
		.ref('.info/connected')
		.on('value', (snap) => {
			store.dispatch(setConnectionState(snap.val()));
		});
};
