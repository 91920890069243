import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(/gfx/dredition-black.svg) no-repeat white;
    background-size: 90px;
    background-position: 10px 10px;
    z-index: 9999;
    color: #ff5e5e;
    font-size: 14px;
    text-align: center;
    padding-top: 80px;

    a {
        color: red;
        text-decoration: none;
        border-bottom: 1px solid red;

        &:hover {
            color: #584c6d;
            border-color: #584c6d;
        }
    }

    .bi-misc-mood-sad {
        font-size: 64px;
        color: #e28888;
    }
}
`;

const BrowserNotSupported = () => {
	return (
		<Wrapper>
			<i className="bi-misc-mood-sad"></i>
			<p>
				<b>Sorry, you&apos;re using an unsupported browser.</b> Only <a href="https://www.google.com/chrome/">Chrome</a>
				, <a href="https://www.apple.com/safari/">Safari</a>, and{' '}
				<a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a> are supported.
			</p>
		</Wrapper>
	);
};

export default BrowserNotSupported;
