import {pluralize} from './string';

const originalConsoleError = console.error; // eslint-disable-line no-console

const SUPPRESSED_ERRORS = [
	// React 18 warnings from @uirouter/react-hybrid
	'Warning: ReactDOM.render is no longer supported in React 18. Use createRoot instead. Until you switch to the new API, your app will behave as if it\'s running React 17. Learn more: https://reactjs.org/link/switch-to-createroot',
	// React 18 warnings from react-bootstrap
	'Warning: ReactDOM.unstable_renderSubtreeIntoContainer() is no longer supported in React 18. Consider using a portal instead. Until you switch to the createRoot API, your app will behave as if it\'s running React 17. Learn more: https://reactjs.org/link/switch-to-createroot'
];

export function shouldSuppressError(message) {
	return SUPPRESSED_ERRORS.some((err) => message.includes(err));
}

export function patchConsoleErrors() {
	if (!window.testMode) {
		console.log(`[utils/console] ${pluralize('error', SUPPRESSED_ERRORS.length, true)} suppressed`); // eslint-disable-line no-console
	}

	// eslint-disable-next-line no-console
	console.error = (message, ...args) => {
		if (!shouldSuppressError(String(message))) {
			return originalConsoleError.apply(console, [message, ...args]);
		}
	};
}
