import React from 'react';
import {createRoot} from 'react-dom/client';
import {initSentry} from '../../utils/sentry';
import {initFirebase} from '../../store/firebase';
import {getUser, isAuthenticated, redirectToLogin, logout} from '../../utils/auth';
import {updateDefaultLocale} from '../../utils/date';
import {patchConsoleErrors} from '../../utils/console';
import storage from '../../utils/storage';
import {isSupported} from '../../utils/browser';
import errorHandler from '../../utils/error-handler';
import migrate from '../../utils/migrate';
import AppLoader from './AppLoader';
import BrowserNotSupported from './BrowserNotSupported';

import '../../styles';

patchConsoleErrors();
updateDefaultLocale();

// Fix for the invalid code in https://github.com/json-schema-form/angular-schema-form/blob/0.8.13/src/services/builder.js#L135 (missing var statement in front of "state")
// Throws ReferenceError: state is not defined" from schema-form.min.js
globalThis.state = {};

(async () => {
	if (!isSupported()) {
		createRoot(document.getElementById('app')).render(<BrowserNotSupported/>);
		return;
	}

	await storage.init();

	if (globalThis.appConfig.sentryApiKey) {
		initSentry(globalThis.appConfig);
	}

	// Migrate from old versions
	await migrate(globalThis.appConfig);

	if (!getUser() || !isAuthenticated()) {
		await logout();
		redirectToLogin(location);
		return;
	}

	try {
		await initFirebase();
	} catch (err) {
		errorHandler(err);
	}

	// Prevent browser to follow anchors with href="#" (used heavily by bootstrap)
	document.addEventListener('click', (event) => {
		const {target} = event;

		if (target.nodeName === 'A' && target.getAttribute('href') === '#') {
			event.preventDefault();
		}
	});

	if (!globalThis.appConfig.streamsUrl) {
		console.error('Realtime not enabled. Missing "streamsUrl" setting in config.json'); // eslint-disable-line no-console
	}

	createRoot(document.getElementById('app')).render(<AppLoader/>);
})();
